import React, { useRef, useEffect } from "react";
// import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { srConfig } from "../config";
import sr from "../utils/sr";
import { usePrefersReducedMotion } from "../hooks";
import { Icon } from "@iconify/react";
import ReactTooltip from "react-tooltip";
import { PortableText } from "@portabletext/react";

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-gap: 50px;
    margin-bottom: 50px;
    @media (max-width: 768px) {
      ${"" /* display: block; */}
      grid-template-columns: 1fr !important;
    }

    &.right-content {
      grid-template-columns: 2fr 3fr;
    }
    &.left-content {
      grid-template-columns: 3fr 2fr;
    }
    &.full-width {
      grid-template-columns: 1fr;
    }
  }
`;
const StyledText = styled.div`
  em {
    text-decoration: underline;
    text-decoration-style: wavy;
    text-decoration-color: var(--blue);
  }
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 15px 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-sm);

      &:before {
        content: "▹";
        position: absolute;
        left: 0;
        color: var(--purple);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  max-width: 300px;

  @media (max-width: 768px) {
    ${"" /* margin: 50px auto 0; */}
    margin: 0px auto 0;
    width: 100%;
    &:nth-of-type(2) {
      grid-row: 1;
    }
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    ${"" /* background-color: var(--purple); */}
    margin-bottom: 30px;

    @media (max-width: 768px) {
      margin-bottom: 0;
      margin-left: -5px;
    }

    &:hover,
    &:focus {
      background: transparent;
      outline: 0;

      &:after {
        top: 30px;
        left: 30px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    .img {
      position: relative;
      border-radius: var(--border-radius);
      ${
        "" /* mix-blend-mode: multiply;
      filter: grayscale(100%) contrast(1); */
      }
      transition: var(--transition);
      &:hover {
        transform: scale(1.1);
      }
    }

    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 55px solid var(--purple);
      top: 10px;
      left: 10px;
      z-index: -1;
    }
    ${
      "" /* &:hover {
      transform: scale(1.05);
    } */
    }
  }
`;

const StyledIcons = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 20px 40px;
  a:hover {
    color: inherit;
  }
  svg {
    width: 75px;
    height: 75px;
  }
  .recent-tech-icon {
    background-color: var(--purple);
    font-family: var(--font-mono);
  }
`;

const components = {
  types: {
    image: ({ value }) => (
      <StyledPic>
        <div className="wrapper">
          <img
            className="img"
            src={value._rawAsset.url}
            width={500}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt={value._rawAsset.altText || ""}
          />
        </div>
      </StyledPic>
    ),
  },
  list: {
    bullet: ({ children }) => <ul className="skills-list">{children}</ul>,
  },
  marks: {
    em: ({ children }) => <em>{children}</em>,
  },
};

const About = ({ content }) => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  const firstImage = content.sectionBuilder[0];
  const firstText = content.sectionBuilder[1];
  const secondImage = content.sectionBuilder[2];
  const secondText = content.sectionBuilder[3];
  const recentTechText = content.sectionBuilder[4];
  const recentTechIconList = content.sectionBuilder[5];
  const icons = recentTechIconList._rawIconListItem;

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="section-heading">About Me</h2>

      <div className="inner left-content">
        <StyledText>
          <PortableText
            value={firstText._rawBlockText}
            components={components}
          />
        </StyledText>
        <PortableText value={firstImage} components={components} />
      </div>

      <div className="inner right-content">
        <PortableText value={secondImage} components={components} />
        <StyledText>
          <PortableText
            value={secondText._rawBlockText}
            components={components}
          />
        </StyledText>
      </div>
      <div className="inner full-width">
        <StyledText>
          <PortableText value={recentTechText._rawBlockText} />
          <StyledIcons>
            {icons &&
              icons.map((icon) => (
                <a data-tip={icon.title} key={icon.iconName}>
                  <Icon
                    key={icon._key}
                    icon={icon.iconName}
                    alt={`${icon.title} logo`}
                  />
                </a>
              ))}
            <ReactTooltip
              place="top"
              effect="float"
              className="recent-tech-icon"
              backgroundColor="var(--purple)"
            />
          </StyledIcons>
        </StyledText>
      </div>
    </StyledAboutSection>
  );
};

export default About;
