import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { srConfig } from "../config";
import sr from "../utils/sr";
import { usePrefersReducedMotion } from "../hooks";
import { PortableText } from "@portabletext/react";

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Contact = ({ content, email }) => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="title">{content.headingText}</h2>

      <PortableText value={content.content} />

      <a className="email-link" href={`mailto:${email}`}>
        Say Hello 👋
      </a>
    </StyledContactSection>
  );
};

export default Contact;
