import * as React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import Hero from "../components/hero";
import About from "../components/about";
import Jobs from "../components/jobs";
import Contact from "../components/contact";
import FeaturedProjects from "../components/featuredProjects";

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

// page query
export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      author {
        name
        email
        headline
        _rawByline
      }
    }
    sections: allSanityPage(filter: { pageName: { eq: "Home" } }) {
      nodes {
        id
        pageName
        sectionBuilder {
          _id
          sectionName
          headingText
          sectionBuilder {
            ... on SanityBlockObject {
              _key
              _type
              _rawBlockText
            }
            ... on SanityImage {
              _key
              _type
              _rawAsset(resolveReferences: { maxDepth: 10 })
            }
            ... on SanityIconList {
              _key
              _type
              _rawIconListItem(resolveReferences: { maxDepth: 10 })
            }
          }
        }
      }
    }
  }
`;

function filterSection(sectionName, sectionArray) {
  const section = sectionArray.find((obj) => obj.sectionName === sectionName);
  return section;
}

// markup
const IndexPage = ({ data, location }) => {
  const site = (data || {}).site;
  const sections = (data || {}).sections.nodes[0].sectionBuilder;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  if (!sections) {
    throw new Error(
      "Missing Page Sections. Open the studio at http://localhost:3333 and add some content"
    );
  }

  const { author } = site;
  const aboutContent = filterSection("About", sections);
  const contactContent = filterSection("Contact", sections);

  return (
    <Layout location={location}>
      <StyledMainContainer className="fillHeight">
        <Hero author={author} />
        <About content={aboutContent} />
        <Jobs />
        <FeaturedProjects />
        <Contact content={contactContent} email={author.email} />
      </StyledMainContainer>
    </Layout>
  );
};

export default IndexPage;
